@import "semantic.less";
@import (inline) "~codemirror/lib/codemirror.css";
@import url('~semantic-ui-less/themes/default/globals/site.variables');

#GithubBadges, #DonateBadges {
  display: flex;

  & #donate {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    padding: 3px 10px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    color: #24292e;
    background-color: #eee;
    background-image: linear-gradient(to bottom,#fcfcfc 0,#eee 100%);
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-repeat: repeat-x;
    background-position: -1px -1px;
    background-size: 110% 110%;
    border: 1px solid rgba(27,31,35,.2);
    border-radius: .25em;

    &:hover {
      background-color: #ddd;
      background-image: linear-gradient(to bottom,#eee 0,#ddd 100%);
      border-color: #ccc;
    }

    &:active {
      background-image: none;
      background-color: #dcdcdc;
      border-color: #b5b5b5;
      box-shadow: inset 0 2px 4px rgba(0,0,0,.15);
    }

    & #donate-heart {
      display: inline-block;
      vertical-align: middle;
      color: #ea4aaa;
      fill: currentColor;
      margin-right: 2px;
      margin-bottom: 2px;
    }
  }

  @media only screen and (max-width: @largestMobileScreen) {
    & {
      justify-content: center;
    }
  }
}

#DonateWallets {
  word-break: break-all;
}

.donate-badge {
  margin-right: 4px;
}

.CodeMirror {
  height: 200px;
}

.DropZone {
  padding: 1em;
  height: 200px;
  border-width: 2px;
  border-color: #ccc;
  border-style: dashed;
  border-radius: 5px;
}

.OptionsForm.ui.form {
  label {
    font-size: 0.8em;
  }
}

.ui.labels > .label {
  font-family: monospace;
}

code {
  padding: 0.2em;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
}

.evaluatedCode {
  white-space: pre;
  padding: 0.2em;
  background-color: rgba(0, 0, 0, 0.04);
  overflow: auto;
  max-height: 200px;
}

table#Options {
  display: inline-block;
  word-wrap: break-word;
}

img#Logo {
  width: 100%;
  max-width: 300px;
}

.table code {
  word-break: break-word;
}

pre#Html {
  display: flex;
  margin: 0;
  padding: 0;
  
  & code {
    width: 100%;
  }
}

pre code {
  display: block;
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  border-radius: 5px;
}

code {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: #e1e1e4;
  padding: .2em .4em;
  margin: 0;
  font-size: 85%;
  border-radius: 5px;
  white-space: pre;
}
